import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Alert
} from '@mui/material';
import { authenticatedFetch, formatDate } from '../utils/utils';

export default function Tasks() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [unsignedStudents, setUnsignedStudents] = useState([]);
    const [missingRecords, setMissingRecords] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        setLoading(true);
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await authenticatedFetch(`/attendance/missing-records/${today}`);
            
            if (!response.ok) {
                throw new Error('Failed to fetch tasks');
            }

            const data = await response.json();
            setUnsignedStudents(data.unsignedToday);
            setMissingRecords(data.missingRecords);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (date) => {
        if (date) {
            navigate(`/attendance/${date}`);
        } else {
            navigate('/attendance');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
                <Table sx={{ tableLayout: 'auto', borderCollapse: 'separate', borderSpacing: 0 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f0f0f0' }}>
                                Students who haven't signed in by 12
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unsignedStudents.length === 0 ? (
                            <TableRow>
                                <TableCell align="center">None</TableCell>
                            </TableRow>
                        ) : (
                            unsignedStudents.map(student => (
                                <TableRow 
                                    key={student.id}
                                    onClick={() => handleRowClick(null)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Typography component="span" color="primary">
                                            {student.nickname}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ tableLayout: 'auto', borderCollapse: 'separate', borderSpacing: 0 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f0f0f0' }}>
                                Missing attendance records
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {missingRecords.length === 0 ? (
                            <TableRow>
                                <TableCell align="center">None</TableCell>
                            </TableRow>
                        ) : (
                            missingRecords.map(({ date, student }) => (
                                <TableRow 
                                    key={`${date}-${student.id}`}
                                    onClick={() => handleRowClick(date)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Typography component="span" color="text.secondary">
                                            {formatDate(date)}{' '}
                                        </Typography>
                                        <Typography component="span" color="primary">
                                            {student.nickname}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}