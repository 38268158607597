// src/components/Navigation.js
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import ModeSwitchModal from './ModeSwitchModal';
import SettingsIcon from '@mui/icons-material/Settings'; // Import the icon you want to use for switching modes

export default function Navigation({ toggleAdminMode, isAdmin }) {
    const [showModeSwitchModal, setShowModeSwitchModal] = useState(false);

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    SVS Attendance
                </Typography>
                <Button color="inherit" component={Link} to="/attendance">Attendance</Button>
                <Button color="inherit" component={Link} to="/off-campus">Off-campus</Button>
                { isAdmin && ( <Button color="inherit" component={Link} to="/tasks">Tasks</Button> ) }
                { isAdmin && ( <Button color="inherit" component={Link} to="/violations">Fines</Button> ) }
                { isAdmin && ( <Button color="inherit" component={Link} to="/people">People</Button> ) }
                { isAdmin && ( <Button color="inherit" component={Link} to="/destinations">Destinations</Button> ) }
                { isAdmin && ( <Button color="inherit" component={Link} to="/calendar">Calendar</Button> ) }
                <IconButton color="inherit" onClick={() => setShowModeSwitchModal(true)}>
                    <SettingsIcon />
                </IconButton>
            </Toolbar>
            <ModeSwitchModal
                open={showModeSwitchModal}
                onClose={() => setShowModeSwitchModal(false)}
                onSwitchMode={(mode) => {
                    toggleAdminMode(mode);
                    setShowModeSwitchModal(false);
                }}
                onReloadApp={() => {
                    window.location.reload();
                }}
            />
        </AppBar>
    );
}
