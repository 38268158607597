import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Modal, Button, Typography, Box
} from '@mui/material';
import { formatDate } from '../utils/utils';

export default function ViolationsList({ 
    violations, 
    onMarkPaid, 
    onUnmarkPaid,
    modalOpen,
    selectedViolation,
    actionType,
    onModalClose,
    handleAction
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const formatDateTime = (timestamp) => {
        if (!timestamp) return 'No date';
        
        try {
            const date = new Date(timestamp);
            if (isNaN(date.getTime())) {
                console.error('Invalid date from timestamp:', timestamp);
                return 'Invalid date';
            }
            
            return date.toLocaleString('en-US', {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                year: '2-digit',
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                timeZone: 'America/New_York'
            });
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Error formatting date';
        }
    };

    const renderViolationRows = (violationsArray, title) => {
        const totalUnpaidAmount = violationsArray.reduce((sum, v) => sum + v.amount, 0);
        
        return (
            <>
                <TableRow>
                    <TableCell colSpan={6} align="center" style={{ background: '#f5f5f5' }}>
                        {title} (${totalUnpaidAmount})
                    </TableCell>
                </TableRow>
                {violationsArray.length > 0 ? (
                    violationsArray.map((violation) => (
                        <TableRow 
                            key={violation.id}
                            onClick={() => !violation.complaint && onMarkPaid(violation)}
                            sx={{
                                cursor: !violation.complaint ? 'pointer' : 'default',
                                '&:hover': {
                                    backgroundColor: !violation.complaint ? '#f5f5f5' : 'inherit',
                                },
                            }}
                        >
                            <TableCell>{formatDateTime(violation.timestamp)}</TableCell>
                            <TableCell>{violation.law_code}</TableCell>
                            <TableCell>{violation.description}</TableCell>
                            <TableCell>{violation.complaint ? '✓' : '-'}</TableCell>
                            <TableCell>{violation.paid ? '✓' : '-'}</TableCell>
                            <TableCell>${violation.amount}</TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={6} align="center">None</TableCell>
                    </TableRow>
                )}
            </>
        );
    };

    const unpaidViolations = violations.filter(v => !v.paid && !v.complaint);
    const paidViolations = violations.filter(v => v.paid || v.complaint);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="violations table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date & Time</TableCell>
                            <TableCell>Law Code</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Complaint?</TableCell>
                            <TableCell>Paid?</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderViolationRows(unpaidViolations, "Outstanding")}
                        {renderViolationRows(paidViolations, "Paid")}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={modalOpen}
                onClose={onModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {actionType === 'markPaid' ? 'Mark Fine as Paid' : 'Unmark Fine as Paid'}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to {actionType === 'markPaid' ? 'mark' : 'unmark'} this fine as paid?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onModalClose} sx={{ mr: 1 }}>Cancel</Button>
                        <Button onClick={handleAction} variant="contained" color="primary">
                            {actionType === 'markPaid' ? 'Mark Paid' : 'Unmark Paid'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
